import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../../components/layoutDoc";
import Seo from "../../components/seo";

import Introduction from '../../images/introduction.png'

function About() {
    return (
        <Layout page="Introduction" pageInner="Introduction">
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Introduction" />
            <Container fluid='lg' className="py-md-5 py-4">
                <Row className="justify-content-center mb-lg-5 mb-3">
                    <Col lg={12} className="mb-3">
                        <div className="section-title text-center">
                            <h2>Introduction</h2>
                        </div>
                        <p>HedgePay provides holders with passive rewards that are independent of transactional volume.  Most reward tokens depend on reflections based on volume of transactions, which leads to great rewards when volume is high but no rewards when volume drops. Our solution is to continue development on our software division that is building the Ecommerce portion of our website that offers affordable DeFi services.</p>
                        <p>Our software division is also constructing a freelancing platform that allows creators and business owners an arena to connect with each other. Once connected, HedgePay will function as an escrow contract between the two allowing seamless transactions utilizing fiat or crypto for payment processing. This software division of HedgePay generates revenue that shares 50% of profits with the HedgeFi Algorithm that utilizes stable liquidity mining to provide passive rewards to holders.</p>
                    </Col>
                </Row>

                <div className='text-center'>
                    <img src={Introduction} alt="Introduction" className='img-fluid' />
                </div>
            </Container>
        </Layout>
    );
}

export default About;
